<template>
  <div>
    <div class="wapper">
      <el-button type="primary" size="mini" class="open" v-if="!dialogVisible"
        @click="dialogVisible = true">查看轨迹信息</el-button>
      <el-dialog :modal="false" :visible.sync="dialogVisible" width="100%" :show-close="false"
        :modal-append-to-body="true">
        <div class="tab">
          <el-button class="close" @click="closedialog()">收起</el-button>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="车辆轨迹" name="first">
              <!-- 表格弹出框 -->
              <el-table :data="guijiData" height="250px" style="width: 100%"
                 @row-click="mvoeTrack"
              
       >
                <el-table-column type="index" width="50"></el-table-column>
                <!-- <el-table-column prop="sysOrgCode_dictText" label="地址"></el-table-column>
                <el-table-column prop="usetime" label="时间" width="80"></el-table-column>
                <el-table-column prop="name" label="速度km" width="80"></el-table-column>
                <el-table-column prop="name" label="海拔" width="80"></el-table-column>
                <el-table-column prop="name" label="方向" width="80"></el-table-column>
                <el-table-column prop="initialTime" label="开始时间" width="180"></el-table-column>
                <el-table-column prop="terminationTime" label="结束时间" width="180"></el-table-column>
                <el-table-column prop="name" label="停留时间" width="100"></el-table-column> -->
                <el-table-column prop="vehicleno" label="车牌" width="180"></el-table-column>
                <el-table-column prop="lat" label="lat"></el-table-column>
                <el-table-column prop="lon" label="lon"></el-table-column>
             
                <el-table-column prop="initialresidencetime" label="开始停留时间" width="180"></el-table-column>
                   <el-table-column prop="endTime" label="结束停留时间" width="180"></el-table-column>
                <el-table-column prop="dwellTime" label="停留时间" width="100"></el-table-column>
                <el-table-column prop="vec2" label="速度km" width="80"></el-table-column>
                <el-table-column prop="altitude" label="海拔" width="80"></el-table-column>
                <el-table-column prop="direction" label="方向" width="80"></el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="卡口" name="second">
              <el-table :data="videoData" height="250px" @row-click="handleRowClick" style="width: 100%"
                v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="pointname" label="卡口"></el-table-column>
                <!-- 操作 -->
                <!-- 操作列 -->
                <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <!-- 查看按钮 -->
                    <el-button size="mini" @click="openvideo(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-dialog>
      <!-- 视频弹出框 -->
      <van-popup v-model="videodiashow" closeable position="top" :round="true" :overlay="false" z-index="999999"
        id="videopup">
        <div class="video">
          <video ref="videos" controls :src="videoSrc"></video>
        </div>
      </van-popup>
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import { getvideocar, getbsnTrackingTrucks } from "@/api/index";
import maps from "../utils/maps";
let BM;
let map;
export default {
  props: {
    datalist: Array
  },
  data() {
    return {
      show: true,
      // 控制弹出视频
      videodiashow: false,
      // 控制整个弹框
      dialogVisible: false,
      // 车牌号
      Licenseplatenumber: null,
      // 视频路径
      videoSrc: null,
      activeName: "first",
      tableData: [],
      videoData: [],
      loading: false,
      marker: null,
      //线路
      polyline: null,
      //走过的线路
      passed: null,
      //轨迹车辆
      car: null,
      transformedData: [],
      guijiData: [],
      oldguijiData: [],
      nowcar: null,
      nowData: [],
      kakoulist: [],
      markerList: [],
      linePoint:null

    };
  },
  watch: {
    videodiashow: function (newval, oldval) {
      console.log(newval, oldval, this);
      if (!newval) {
        this.$refs.videos.pause();
      }
    },  // 深度监听 datalist 数组中的对象变化
    datalist: {
      handler(newData, oldData) {
        console.log('datalist changed:', newData);
        // this.nowData(newData);
        this.handleDataChange(newData);
      },
      deep: true // 监听数组中对象的所有内部属性变化
    }
  },
  mounted() {
    this.$bus.$on("opentable", val => {

      console.log('%c [  ]-122', 'font-size:13px; background:pink; color:#bf2c9f;', val)
      map.setView([val[0].currentLat, val[0].currentLon], 20);
      this.dialogVisible = false;
      val.forEach(item => {
        item.usetime =
          Date.parse(item.terminationTime) - Date.parse(item.initialTime)
        item.usetime = this.formattime(item.usetime)
      });
      this.tableData = val;
      this.Licenseplatenumber = val[0].vehicleno;
      this.nowcar = val[0];

      //创建摄像头点位
      this.getvideo();

      console.log('%c [  ]-152', 'font-size:13px; background:pink; color:#bf2c9f;', this.nowData)


      //删除卡口
      // 循环清理卡口
      if (this.kakoulist != undefined && this.kakoulist.length > 0) {
        this.kakoulist.forEach(item => {
          map.removeLayer(item)
        })
      }

      console.log('%c [  ]-156', 'font-size:13px; background:pink; color:#bf2c9f;', this.polyline)

      this.addMarkers(this.nowData);

    });
    maps.then(() => {
      BM = window.BM
      // 这里URL填写的是离线地图的服务器地址
      BM.Config.HTTP_URL = 'http://117.141.117.139:9000'
      map = BM.map('map', 'bigemap.cv2bc6x1',
        {
          crs: BM.CRS.EPSG4326, center: [23.64500617980957, 108.2520751953125],
          // zoom: 3,
          zoomControl: true
        });
      map.fitBounds([[20.900972366333008, 104.44775390625], [26.389039993286133, 112.056396484375]]).setZoom(8);

      console.log('%c [  ]-131', 'font-size:13px; background:pink; color:#bf2c9f;', this.tableData)

      // // 创建点位
      // this.addMarkers(mapList);
      // //生成轨迹
      // this.addTrack(mapList);
      // //添加卡口
      // this.addMountMarkers(this.mountList);
    });
  },
  methods: {
    //开始移动
    startTracking(data) {
      //如果没有车辆和轨迹就报错
      if (this.car == null || this.polyline == null) {
        this.$message.error('请先选择车辆和查询轨迹');
        return;
      }
      if (data.type == 1) {
        this.car.moveAlong(this.polyline, data.speed, {
          replay: false
        });
      } else if (data.type == 2) {
        try {
          this.car.resumeMove();
        } catch (error) {
          this.$message('先点击开始才能点击继续哦');
        }
      } else if (data.type == 3) {
        this.car.pauseMove();
      } else if (data.type == 4) {
        this.car.setSpeed(this.car.getSpeed() + 300);
      }

    },
    // 获取轨迹
    // getguiji() {
    //   getbsnTrackingTrucks({
    //     vehicleno: this.Licenseplatenumber,
    //     pageSize: 10000
    //   }).then(res => {
    //     console.log('%c [  ]-174', 'font-size:13px; background:pink; color:#bf2c9f;', res)
    //     this.loading2 = false;
    //     this.guijiData = res.records;

    //     // 按照 createtime 升序排序（从最早到最晚）
    //     this.guijiData.sort((a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime());

    //     // 倒序遍历数据，设置开始时间、结束时间和停留时间
    //     for (let i = 0; i < this.guijiData.length - 1; i++) {
    //       // 设置开始时间为当前记录的创建时间
    //       this.guijiData[i].startTime = this.guijiData[i].createtime;

    //       // 设置结束时间为下一条记录的创建时间
    //       this.guijiData[i].endTime = this.guijiData[i + 1].createtime;

    //       // 计算停留时间（结束时间 - 创建时间）
    //       const currentCreateTime = new Date(this.guijiData[i].createtime).getTime();
    //       const endCreateTime = new Date(this.guijiData[i].endTime).getTime();
    //       const dwellTimeSeconds = (endCreateTime - currentCreateTime) / 1000; // 转换为秒

    //       // 将停留时间转换为时分秒格式
    //       const hours = Math.floor(dwellTimeSeconds / 3600);
    //       const minutes = Math.floor((dwellTimeSeconds % 3600) / 60);
    //       const seconds = Math.floor(dwellTimeSeconds % 60);

    //       // 拼接中文格式的停留时间
    //       this.guijiData[i].dwellTime = `${hours}小时${minutes}分钟${seconds}秒`;
    //     }

    //     // 处理最后一条记录（没有下一条记录，因此没有结束时间）
    //     if (this.guijiData.length > 0) {
    //       const lastRecordIndex = this.guijiData.length - 1;
    //       this.guijiData[lastRecordIndex].startTime = this.guijiData[lastRecordIndex].createtime;
    //       this.guijiData[lastRecordIndex].endTime = null;  // 最后一条记录没有结束时间
    //       this.guijiData[lastRecordIndex].dwellTime = "0小时0分钟0秒";   // 最后一条记录的停留时间设为0
    //     }
    //     this.oldguijiData = this.guijiData;

    //   }).catch(error => {
    //     console.error('Error fetching tracking data:', error);
    //     this.loading2 = false;
    //   });
    // }
    // ,

    getguiji() {
  getbsnTrackingTrucks({
    vehicleno: this.Licenseplatenumber,
    pageSize: 10000
  }).then(res => {
    
    this.loading2 = false;
    this.guijiData = res.records;
    console.log('%c [  ]-174', 'font-size:13px; background:pink; color:#bf2c9f;', res.records);

    this.guijiData.sort((a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime());

      // 遍历轨迹数据，计算每个点的 endTime 和 dwellTime
      this.guijiData.forEach((point, index) => {
      // 验证 initialresidencetime 和 residencetime 是否有效，如果无效则设为 0
      const initialDwellTime = this.isValidTime(point.initialresidencetime) ? point.initialresidencetime : null;
           // 如果 initialDwellTime 无效（为空），则跳过当前点
           if (!initialDwellTime) {
                // 将旧数据更新
    this.oldguijiData =this.guijiData;
        return; // 不做任何计算，跳过此点
      }
      const dwellTime = point.residencetime ? point.residencetime * 60 : 0;
          
      // 将 initialresidencetime 转换为 Date 对象
      let startTime = this.parseDate(point.initialresidencetime); // 使用自定义的解析方法
    
      console.log('%c [  ]-284', 'font-size:13px; background:pink; color:#bf2c9f;',startTime.getTime() )
      // 如果转换后的 startTime 无效，使用当前时间
      if (!startTime) {
                          // 将旧数据更新
            this.oldguijiData =this.guijiData;
                return; // 不做任何计算，跳过此点
      }

      // 计算结束时间 (endTime)
      const endTime = new Date(startTime.getTime() + (dwellTime) * 1000); // 转为毫秒计算
      // Invalid Date
      console.log('%c [  ]-294', 'font-size:13px; background:pink; color:#bf2c9f;', point.residencetime)

      // 格式化停留时间 (dwellTime) 为 "0小时0分钟0秒" 格式
      const formattedDwellTime = this.formatDwellTime(dwellTime);
  

    // 格式化结束时间为 "yyyy-MM-dd HH:mm:ss"
    point.endTime = this.formatDate(endTime); // 格式化 endTime
    console.log('%c [  ]-294', 'font-size:13px; background:pink; color:#bf2c9f;', point.endTime )
      // console.log('%c [  ]-291', 'font-size:13px; background:pink; color:#bf2c9f;',endTime )
      point.dwellTime = formattedDwellTime; // 添加格式化后的停留时间
    });


    // 将旧数据更新
    this.oldguijiData =this.guijiData;

  }).catch(error => {
    console.error('Error fetching tracking data:', error);
    this.loading2 = false;
  });
},
// 格式化时间为 "yyyy-MM-dd HH:mm:ss"
// 格式化时间为 "yyyy-MM-dd HH:mm:ss"
formatDate(date) {
  // 确保传入的是一个有效的 Date 对象
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.error('Invalid date object passed to formatDate:', date);
    return ''; // 如果无效，返回空字符串
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
},
// 格式化停留时间为 "0小时0分钟0秒" 格式
formatDwellTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}小时${minutes}分钟${remainingSeconds}秒`;
},

// 检查时间是否有效
isValidTime(time) {
  return typeof time === 'string' && time.trim() !== '';
},

// 解析日期字符串 "2024-10-21 11:53:00" 为 Date 对象
parseDate(dateString) {
  if (!dateString) {
    console.error('Invalid date string:', dateString);
    return null; // 如果时间为空或无效，返回 null
  }

  // 将 "2024-10-21 11:53:00" 转换为 "2024/10/21 11:53:00" 格式，JavaScript 可以解析这个格式
  const formattedDate = dateString.replace(" ", "T"); // 替换空格为 "T"
  const date = new Date(formattedDate);

  if (isNaN(date.getTime())) {
    console.error('Invalid date after parsing:', dateString);
    return null; // 如果解析失败，返回 null
  }

  return date;
},



    //添加卡口
    addMountMarkers(data) { // data 表示传进来的数据，包括坐标、图标、文字等信息

      // 循环清理卡口
      if (this.kakoulist && this.kakoulist.length > 0) {
        this.kakoulist.forEach(item => {
          map.removeLayer(item)
        })
      }

      if (data == undefined) {

        return;
      }
      let that = this;

      console.log('%c [  ]-210', 'font-size:13px; background:pink; color:#bf2c9f;', data)
      // 遍历数据数组并为每个点添加标记
      data.forEach(item => {
        let marker = BM.marker([item.latitude, item.longitude], {
          icon: BM.icon({
            iconUrl: 'http://49.234.87.131:1800/marker/dianlijinggai.png',
            iconSize: [48, 48],
            iconAnchor: [24, 36]
          })
        }).addTo(map);
        this.kakoulist.push(marker);

        // 为每个点位添加监听事件和工具提示
        marker.on('click', (e) => {
          console.log('%c [  ]-123', 'font-size:13px; background:pink; color:#bf2c9f;', e)
          map.setView([e.target._latlng.lat, e.target._latlng.lng], 20);

          // 执行点击事件
        }).addTo(map);

        //底部车辆文字
        marker.bindTooltip(item.pointname, { direction: 'bottom', permanent: true, className: 'my_tooltip' });
        //点击弹窗
        //  marker.bindPopup(`<p>${item.vehicleno}</p><p><img src="http://www.bigemap.com/upload/columnthumbnail/20180328/5abb657fa458f.png" alt=""></p>`);
      });
    },
    seachTrackData(data) {
        
      console.log('%c [  ]-294', 'font-size:13px; background:pink; color:#bf2c9f;', )
      try {
        // 获取用户输入的参数：停留时间（分钟），开始时间和结束时间
        const { stopTime, startTime, endTime } = data;
        // 提示信息：没有轨迹数据
        if (this.oldguijiData.length === 0) {
          this.$message('暂无轨迹信息');
          this.guijiData = [];
          return;
        }

        // 将停留时间（分钟）转换为秒
        const stopTimeInSeconds = stopTime * 60;

        // 用于筛选后的轨迹数据
        let filteredData = this.oldguijiData;

        // 筛选停留时间：大于等于用户输入的停留时间（秒）
        if (stopTime) {
          filteredData = filteredData.filter(item => {
            try {
              const itemDwellTime = item.dwellTime; // 假设dwellTime是“时:分:秒”格式
              if (!itemDwellTime) {
                console.warn('某条记录没有停留时间字段，跳过此记录');
                return false; // 没有停留时间的记录跳过
              }
              const [hours, minutes, seconds] = itemDwellTime.split('小时');
              const [minutePart, secondPart] = minutes.split('分钟');
              const secondsPart = secondPart.replace('秒', '');
              // 转换为总秒数进行比较
              const totalDwellSeconds = parseInt(hours) * 3600 + parseInt(minutePart) * 60 + parseInt(secondsPart);

              if (isNaN(totalDwellSeconds)) {
                console.warn(`记录的停留时间格式无效：${itemDwellTime}`);
                return false; // 如果停留时间格式无效，则跳过该条记录
              }
              return totalDwellSeconds >= stopTimeInSeconds; // 只保留停留时间大于等于输入的记录
            } catch (error) {
              console.error('筛选停留时间时出错：', error);
              return false;
            }
          });
        }

        // 筛选开始时间：大于等于用户输入的开始时间
        if (startTime) {
          filteredData = filteredData.filter(item => {
            try {
              const itemStartTime = new Date(item.createtime).getTime();
              if (isNaN(itemStartTime)) {
                console.warn(`某条记录的创建时间无效，跳过此记录：${item.createtime}`);
                return false; // 无效的时间格式跳过
              }
              return itemStartTime >= new Date(startTime).getTime(); // 只保留创建时间大于等于开始时间的记录
            } catch (error) {
              console.error('筛选开始时间时出错：', error);
              return false;
            }
          });
        }

        // 筛选结束时间：小于等于用户输入的结束时间
        if (endTime) {
          filteredData = filteredData.filter(item => {
            try {
              const itemEndTime = new Date(item.endTime || item.createtime).getTime(); // 若无endTime，取createtime作为结束时间
              if (isNaN(itemEndTime)) {
                console.warn(`某条记录的结束时间无效，跳过此记录：${item.endTime}`);
                return false; // 无效的时间格式跳过
              }
              return itemEndTime <= new Date(endTime).getTime(); // 只保留结束时间小于等于结束时间的记录
            } catch (error) {
              console.error('筛选结束时间时出错：', error);
              return false;
            }
          });
        }

        // 如果没有符合筛选条件的记录
        if (filteredData.length === 0) {
          this.$message('没有符合条件的轨迹数据');
          this.guijiData = [];
          return;
        }

        // 更新筛选后的轨迹数据
        this.guijiData = filteredData;
        console.log('%c [ filteredData ]-402', 'font-size:13px; background:pink; color:#bf2c9f;', filteredData)
        // 调用 addTrack 方法，展示或处理筛选后的轨迹
        this.addTrack();

      } catch (error) {
        // 捕获并输出任何未预料的错误
        console.error('搜索轨迹数据时发生错误：', error);
        this.$message('搜索轨迹数据时发生错误，请稍后重试');
      }
    },
    // 清除地图所有标记
    clearAall(){

      //循环清理点位
      if (this.markerList != undefined && this.markerList.length > 0) {

        console.log('%c [  ]-404', 'font-size:13px; background:pink; color:#bf2c9f;', )
        this.markerList.forEach(item => {
          map.removeLayer(item)
        })
      }
      
      // 循环清理卡口
      if (this.kakoulist && this.kakoulist.length > 0) {

        console.log('%c [  ]-413', 'font-size:13px; background:pink; color:#bf2c9f;', )
        this.kakoulist.forEach(item => {
          map.removeLayer(item)
        })
      }

        console.log('%c [  ]-419', 'font-size:13px; background:pink; color:#bf2c9f;',this.polyline )
        // 循环清理线路
        if (this.polyline != null || this.polyline!= undefined) {


          console.log('%c [  ]-422', 'font-size:13px; background:pink; color:#bf2c9f;', )
          map.removeLayer(this.polyline)

          }
          if (this.passed != null || this.passed  != undefined) {


          map.removeLayer(this.passed)

          }
          //this.car
          if (this.car != null || this.car != undefined) {
            //先暂停车辆
            this.car.pauseMove();
            map.removeLayer(this.car)

          }
          //this.car
          if (this.linePoint != null || this.linePoint != undefined) {
      
            map.removeLayer(this.linePoint)

          }

            

    },

    addTrack() {

      let datalist = [];
      this.clearAall();
      if (this.oldguijiData.length == 0) {
        //提示暂无车辆轨迹信息
        this.$message('暂无轨迹信息');
        return;
      }

      
    

      this.oldguijiData.forEach(item => {
        //字符串转小数
        item.lat = parseFloat(item.lat);
        item.lon = parseFloat(item.lon);
        datalist.push([item.lat, item.lon]);
      })

      //轨迹线路
      this.polyline = BM.polyline(datalist).addTo(map);
      //底部车辆文字
      map.fitBounds(this.polyline.getBounds());
      this.car = BM.marker([this.oldguijiData[0].lat, this.oldguijiData[0].lon], {
        icon: BM.icon({
          iconUrl: 'http://www.bigemap.com/Public/offline/car.png',
          iconAnchor: [25, 15],
        })
      }).addTo(map);

      this.car.bindTooltip(this.oldguijiData[0].vehicleno, { direction: 'bottom', permanent: true, className: 'my_tooltip' });
      //添加一条线段也记录已经路过的点
      this.passed = BM.polyline([
        []
      ], {
        color: 'red'
      }).addTo(map);
      var that = this;
      //车辆更新
      this.car.on('update_position', function (e) {
        //每次坐标更新。然后也更新路径
        // console.log('%c [  ]-291', 'font-size:13px; background:pink; color:#bf2c9f;', 123)
        // console.log('%c [  ]-291', 'font-size:13px; background:pink; color:#bf2c9f;',   that.passed)
        //每次坐标更新。然后也更新路径
        that.passed.setLatLngs(e.path)
      });
    },
    handleDataChange(newData) {
      // 在数据发生变化时执行自定义逻辑
      console.log('数据变化处理方法，新的数据：', newData);
      this.clearAall();
      this.nowData = newData;
      this.addMarkers(newData);


    },
    // 创建多点位
    addMarkers(data) {
      this.clearAall();

      //清楚点位
      // data 表示传进来的数据，包括坐标、图标、文字等信息
      let that = this;
      // 遍历数据数组并为每个点添加标记
      data.forEach(item => {
        let marker = BM.marker([item.currentLat, item.currentLon], {
          icon: BM.icon({
            iconUrl: 'http://www.bigemap.com/Public/offline/car.png',
            iconSize: [60, 30],
            iconAnchor: [24, 36]
          })
        }).addTo(map);
        this.markerList.push(marker);
        // 为每个点位添加监听事件和工具提示
        marker.on('click', (e) => {
          //中心点
          map.setView([e.target._latlng.lat, e.target._latlng.lng], 20);
          // e.target.options.openPopup();
          console.log('%c [  ]-123', 'font-size:13px; background:pink; color:#bf2c9f;', e.target._latlng)
          // 执行点击事件
        }).addTo(map);

        //底部车辆文字
        marker.bindTooltip(item.vehicleno, { direction: 'bottom', permanent: true, className: 'my_tooltip' });
        //点击弹窗
        //  marker.bindPopup(`<p>${item.vehicleno}</p><p><img src="http://www.bigemap.com/upload/columnthumbnail/20180328/5abb657fa458f.png" alt=""></p>`);
      });


    },
    handleRowClick(data) {

      console.log('%c [  ]-238', 'font-size:13px; background:pink; color:#bf2c9f;', data)
      map.setView([data.latitude, data.longitude], 20);
    },
    movePoint(data) {
      console.log('%c [  ]-238', 'font-size:13px; background:pink; color:#bf2c9f;', data)
      map.setView([data.latitude, data.longitude], 20);
    },
    formattime(d) {
      console.log(d)
      return Math.ceil(d / 1000 / 60 / 60 / 24) + "天"
    },
    // 关闭整个表格页面
    closedialog() {
      this.dialogVisible = false;
    },
    // 获取卡口数据
    getvideo() {
      console.log(this.Licenseplatenumber);
      this.tabshow = false;
      this.loading = true;
      getvideocar(this.Licenseplatenumber).then(res => {
        console.log(res);

        console.log('%c [  ]-310', 'font-size:13px; background:pink; color:#bf2c9f;', res)
        this.loading = false;
        this.videoData = res;
        this.addMountMarkers(this.videoData);
      });
      this.getguiji();
    },
    // 打开视频
    openvideo(data) {

      console.log('%c [  ]-258', 'font-size:13px; background:pink; color:#bf2c9f;', data)
      this.videoSrc = data.src;
      this.videodiashow = true;

    },
    mvoeTrack(data){
      console.log('%c [  ]-584', 'font-size:13px; background:pink; color:#bf2c9f;', data)
    //创建点位
    // let marker = BM.marker([data.lat, data.lon], {
    //   icon: BM.icon({
      if (this.polyline != null || this.polyline!= undefined) {

              if (this.linePoint != null || this.linePoint != undefined) {
        
                    map.removeLayer(this.linePoint)
            }
      }else{
            this.addTrack();
      }
  
      //中心点
        map.setView([data.lat, data.lon], 20);
      //为地图添加一个单击事件，更多事件列表请参见 ：http://www.bigemap.com/offlinemaps/api/#map-baselayerchang

      this.linePoint =  
        BM.marker([data.lat, data.lon])
      .bindPopup(`<p>车牌:${data.vehicleno}</p><p>开始时间:${data.createtime}</p><p>结束时间:${data.endTime}</p><p>停留时间:${data.dwellTime}</p>`).
      addTo(map);
      this.linePoint.openPopup()
    },
    handleClick(tab) {
      
      if (tab.name == "second") {
        this.getvideo();
      }
    }
  }
};
</script>

<style lang='scss'>
.wapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 110px);

  //   background-color: #bfa;
  .open {
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 9999;
  }
}

.el-dialog__wrapper {
  position: absolute !important;

  .el-dialog {
    position: absolute;
    bottom: 0;
    margin: 0 !important;
  }

  .el-dialog__header {
    display: none !important;
    height: 0 !important;
  }

  .el-dialog__body {
    padding: 15px;
  }
}

.tab {
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  position: relative;

  .close {
    // float: right;
    position: absolute;
    right: 0;
    z-index: 999;
  }
}

#map {
  width: 100%;
  height: 100%;
}

#videopup {
  width: 700px;
  // height: 400px;
  left: 50%;
  transform: translateX(-50%);
  top: 120px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 20px 10px 20px;

  .video {
    position: relative;
    width: 700px;
    // height: 300px;
    margin: 30px auto;

    video {
      width: 100%;
    }
  }
}
</style>