<template>
  <div class>
    <el-button type="primary" @click="toggle()" class="show">{{this.asideopen?"收起":"展开"}}</el-button>
    <div class="type1 type2">
      <div class="top">
        <div class="lable">
          <div class="key">车牌号码</div>
          <div class="input">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lable">
          <div class="key">车牌颜色</div>
          <div class="input">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="type1 type4">
      <div class="top">
        <div class="lable">
          <div class="key">播放速度</div>
          <div class="input">
            <el-select v-model="value1" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lable">
          <div class="key">停留时间</div>
          <div class="input">
            <el-input v-model="input1" placeholder="请输入内容">
              <template slot="append">分钟以上</template>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="type1 type3">
      <div class="lefticon">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="top">
        <div class="lable">
          <div class="key">开始时间</div>
          <div class="input">
            <el-date-picker v-model="input2" style="width:140px" type="date" placeholder="选择日期"></el-date-picker>
            <!-- <el-input placeholder="请选择日期" suffix-icon="el-icon-date" v-model="input2"></el-input> -->
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lable">
          <div class="key">结束时间</div>
          <div class="input">
            <el-date-picker v-model="input3" type="date" style="width:140px" placeholder="选择日期"></el-date-picker>
            <!-- <el-input placeholder="请选择日期" suffix-icon="el-icon-date" v-model="input3"></el-input> -->
          </div>
        </div>
      </div>
    </div>
    <el-button class="search" type="primary" icon="el-icon-search" 
    @click="searchClick()"
    >查询轨迹</el-button>
    <div class="opwapper">
      <div class="operate">
        <el-button type="primary" icon="el-icon-video-play" @click="startTracking(1)">开始</el-button>
        <el-button type="primary" icon="el-icon-video-play" @click="startTracking(2)">继续</el-button>
      </div>
      <div class="operate">
        <el-button type="primary" icon="el-icon-video-pause" @click="startTracking(3)">暂停</el-button>
        <el-button type="primary" icon="el-icon-video-pause" @click="startTracking(4)">加速</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      input1: "",
      input2: "",
      input3: "",
      options: [
        { label: "黑色", value: "black" },
        { label: "白色", value: "white" }
      ],
      options1: [
        { label: "1", value: 500 },
        { label: "1.5", value: 1000 },
        { label: "3", value: 2000 },
        { label: "10", value: 3000 }
      ],
      value: "",
      value1: 500,
      asideopen: true
    };
  },
  components: {},
  created() {},
  mounted() {
    this.$bus.$on("opentable", val => {
      let data = val[0];
      // console.log(data);
      this.input = data.vehicleno;
      this.input2 = data.initialTime;
      this.input3 = data.updateTime;
    });
  },
  methods: {
    searchClick(){
      
      console.log('%c [  ]-132', 'font-size:13px; background:pink; color:#bf2c9f;', ) 
      // input1 停留时间 input2开始时间 input3 结束时间
      this.$emit("searchClick",{
        stopTime:this.input1,
        startTime:this.input2,
        endTime:this.input3
      });
    },
    startTracking(type){
         //1回放 2 开始 3暂停 4结束 speed速度 
      this.$emit("startTracking",{
        type:type,
        speed:this.value1
   
      });
    },
    toggle() {
      this.asideopen = !this.asideopen;
      this.$emit("toggle", "0");
    }
  }
};
</script>

<style lang='scss' scoped>
.show {
  float: left;
  margin-top: 10px;
}

.type1 {
  user-select: none;
  width: 250px;
  margin: 10px;
  float: left;
  .top,
  .bottom {
    margin-top: 1px;
    .lable {
      background-color: #fff;
      height: 32px;
      width: 250px;
      .key {
        width: 70px;
        float: left;
        font-size: 12px;
        line-height: 32px;
        text-align: center;
        background-color: #e8eaed;
      }
      .input {
        float: left;
        width: 150px;
      }
    }
  }
}
.type2 {
  width: 160px;
  .top,
  .bottom {
    .lable {
      .key {
        width: 60px;
      }
      .input {
        width: 100px;
      }
    }
  }
}
.type4 {
  width: 220px;
  .top,
  .bottom {
    .lable {
      .key {
        width: 60px;
      }
      .input {
        width: 160px;
      }
    }
  }
}
.type3 {
  width: 260px;
  .lefticon {
    width: 20px;
    height: 64px;
    float: left;
    margin-top: 2px;
    background-color: #4096d1;
    line-height: 64px;
    text-align: center;
    i {
      color: #fff;
    }
  }
}
.search {
  float: left;
  margin-top: 20px;
  width: 100px;
  height: 44px;
}
.opwapper {
  float: left;
  margin: 5px;
}
.operate {
  // float: left;
  margin-top: 5px;
  height: 34px;
  margin-left: 10px;
}
</style>