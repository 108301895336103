<template>
  <div>
    <el-row>
      <el-col :span="22">
        <el-select v-model="value" placeholder="请选择"  
        
 
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :clearable="true"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="22"   style="margin-top: 5px;"  >
        <el-select v-model="value1" placeholder="请选择">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :clearable="true"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22">
        <el-input v-model="input" placeholder="车牌号"></el-input>
      </el-col>
    </el-row>
    <el-row>
 
      <el-col :span="4">
        <el-button type="primary"  @click="getlist()">搜索</el-button>
      </el-col>
    </el-row>
    <template>
      <el-table :data="tableData" height="650px"
      :cell-style="{'text-align':'center'}"
      @row-click="opendialog" style="width: 100%">
        <el-table-column prop="vehicleno" align="center" label="车牌号"  ></el-table-column>
        <!-- <el-table-column prop="cartype" label="车型"></el-table-column>
        <el-table-column prop="online" label="在线"></el-table-column> -->
      </el-table>
    </template>
    <el-pagination
      layout="prev, pager, next"
      @current-change="getnewval"
      :current-page="currentpage"
      :page-size="pagesize"
      :total="totaldata"
    ></el-pagination>
  </div>
</template>

<script>
import { getheavycar, gettrackcar, getstopcar } from "@/api/index.js";
export default {
  data() {
    return {
      input: "",
      currentpage: 1,
      pagesize: 15,
      options: [
        { label: "重型车", value: 1 },
        // { label: "重型车", value: 4 },
        { label: "跟踪车", value: 2 },
        { label: "停留车", value: 3 }
      ],
      value: 1,
      options1: [
        { label: "在线", value: 1 },
        { label: "不在线", value: 2 }
      ],
      value1: 1,
      totaldata: 0,
      tableData: [],
      alltabledata: [],
      datalist: {}
    };
  },
  watch: {
    // 深度监听对象的变化
    'tableData': {
      handler(newData) {
        // 数据发生变化时，通知父组件
        this.$emit('update-datalist', newData);
      },
      deep: true  // 这个选项会监听数组内对象的变化
    }
  },
  components: {},
  created() {
    this.getlist();
  },
  methods: {
    opendialog(data) {
      console.log('%c [ data ]-94', 'font-size:13px; background:pink; color:#bf2c9f;', data)

   
        const res = [data]
        this.$bus.$emit("opentable", res);
      
    },
    getnewval(e) {
      this.tableData = this.alltabledata.slice((e - 1) * 15, e * 15);
    },
    // 获取全部
    async getall(params) {
  // 获取a, b, c的数据
  let a = await this.getlist1(params);
  let b = await this.getlist2(params);
  let c = await this.getlist3(params);

  // 将 b 和 c 合并到一起，以便同时替换a中的数据
  let bAndC = [...b, ...c];

  // 创建一个 Map 来快速查找并替换 vehicleno
  let vehicleMap = new Map();
  bAndC.forEach(item => {
    // 将 vehicleno 作为key, 并将整个对象作为值存储
    vehicleMap.set(item.vehicleno, item);
  });

  // 遍历 a 数据并替换相应的 vehicleno
  let updatedData = a.map(item => {
    if (vehicleMap.has(item.vehicleno)) {
      // 如果 vehicleno 存在于 b 或 c 中, 用它们的数据替换
      return vehicleMap.get(item.vehicleno);
    }
    return item; // 否则保持原数据
  });

  // 更新表格数据
  this.totaldata = updatedData.length;
  this.tableData = updatedData.slice(0, 15);

  console.log('%c [  ]-222', 'font-size:13px; background:pink; color:#bf2c9f;', this.tableData);

  // 如果有数据, 继续处理...
},
    getlist1(params) {
      let result = [];
      return new Promise((resolve, reject) => {
        getheavycar(params).then(res => {

          console.log('%c [  ]-124', 'font-size:13px; background:pink; color:#bf2c9f;',res )

          let data =  res.records;
      
          data.forEach(item => {
            item.online = "在线";
            item.cartype = "重型车";
          });
    resolve(data);
        });
      });
    },
    getlist2(params) {
      return new Promise((resolve, reject) => {
        gettrackcar(params).then(res => {
          let data =  res.records;
      
          console.log('%c [  ]-125', 'font-size:13px; background:pink; color:#bf2c9f;',res )
            data.forEach(item => {
              item.online = "在线";
              item.cartype = "跟踪车";
              item.currentLon = item.terminationPointLon;
              item.currentLat = item.terminationPointLat;
            });
          resolve(data);
        });
      });
    },
    getlist3(params) {
      return new Promise((resolve, reject) => {
        getstopcar(params).then(res => {
          console.log('%c [  ]-126', 'font-size:13px; background:pink; color:#bf2c9f;',res )
          let data =  res.records;
      
   
            data.forEach(item => {
              item.online = "在线";
              item.cartype = "停留车";
           
            });
          resolve(data);
        });
      });
    },
    getlist1a(params) {
      let result = [];
      return new Promise((resolve, reject) => {
        getheavycar(params).then(res => {

          console.log('%c [  ]-124', 'font-size:13px; background:pink; color:#bf2c9f;',res )

          this.tableData =  res.records;
          this.totaldata = res.total;
      
         this.tableData.forEach(item => {
            item.online = "在线";
            item.cartype = "重型车";
          });
        });
      });
    },
    getlist2a(params) {
      return new Promise((resolve, reject) => {
        gettrackcar(params).then(res => {
          this.tableData =  res.records;
          this.totaldata = res.total;
      this.tableData.forEach(item => {
         item.online = "在线";
         item.cartype = "跟踪车";
         item.currentLon = item.terminationPointLon;
         item.currentLat = item.terminationPointLat;
       });
        });
      });
    },
    getlist3a(params) {
      return new Promise((resolve, reject) => {
        getstopcar(params).then(res => {
          this.tableData =  res.records;
          this.totaldata = res.total;
      this.tableData.forEach(item => {
         item.online = "在线";
         item.cartype = "停留车";
       });
        });
      });
    },
    getlist(params) {
      if (this.value1 == 2) {
        this.tableData = [];
        this.totaldata = 0;
        return;
      }
      params = {
        pageNo: 1,
        vehicleno: this.input,
        pageSize: 100000
      };
      switch (this.value) {
   
        case 1:
          this.getall(params);
          break;
        case 2:
      this.getlist2a(params);
       
          break;
        case 3:
        this.getlist3a(params);

      
          break;
        case 4:
          this.getlist1a(params);
              break;
      }

      console.log('%c [  ]-221', 'font-size:13px; background:pink; color:#bf2c9f;', this.tableData)
    }
  }
};
</script>

<style lang='scss' scoped>
.el-col {
  margin-left: 5px;
}
.el-row {
  margin-top: 10px;
}
.el-table {
  margin-top: 20px;
}
</style>